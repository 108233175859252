<template>
  <div v-if="isMobile" class="phoneDetailsBox">
    <el-page-header @back="goBack" content="详情页面">
    </el-page-header>
    <el-divider></el-divider>
    <div class="detailsContent">
      <h2 class="detailsContentTitle">{{detail.name}}</h2>
      <div class="timeBox">
        <span>时间：{{detail.createTime|formatTimer()}}</span>
        <span>浏览次数：{{detail.count}}次</span>
      </div>
      <el-divider></el-divider>
      <div v-if="detail.richText " class="detail_html richText ql-editor" v-html="detail.richText"
           @click="clickDom($event)">
      </div>
      <div v-else class="no_detail">
        内容补充中...
      </div>
    </div>

  </div>

  <div v-else class="detailsBox">
    <el-page-header @back="goBack" content="详情页面">
    </el-page-header>
    <el-divider></el-divider>
    <div class="detailsContent">
      <h2 class="title_detail">{{detail.name}}</h2>
      <div class="timeBox">
        <span>时间：{{detail.createTime|formatTimer()}}</span>
        <span>浏览次数：{{detail.count}}次</span>
      </div>
      <el-divider></el-divider>
      <div v-if="detail.richText " class="detail_html richText ql-editor" v-html="detail.richText"
           @click="clickDom($event)">
      </div>
      <div v-else class="no_detail">
        内容补充中...
      </div>
    </div>
  </div>
</template>

<script>
  import Format from '@/utils/format'
  import ItemMixins from "../item.mixins";
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'

  export default {
    mixins: [ItemMixins],
    watch: {
      // 监听路由变化，路由变化的时候请求页面数据
      '$route': function () {
        this.init()
      }
    },
    name: 'details1',
    data() {
      return {
        detail: {}
      }
    },
    filters: {
      // 时间格式化
      formatTimer(timer) {
        return Format(new Date(timer))
      },
    },
    methods: {
      //返回上一级
      goBack() {
        this.$router.go(-1)
        this.$emit('showBanner', 1)
      },
      async init() {
        let id = this.$route.params.id
        this.detail = await this.getDetailById(id)
        this.detail.richText = this.detail.richText.replace(/\<img src="/gi, '<img src="' + this.constUrl)
        this.$emit('topPic', this.detail.picture,this.detail.name)
      },
    },
    mounted() {
      this.init()
    }
  }
</script>
<style lang="less">
  .title_detail{
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    background-image: linear-gradient(to left, #43d5fd, #0385ff);
    line-height: 50px;
    -webkit-background-clip: text;
    color: transparent;
    height: 50px;
  }
  .phoneDetailsBox {
  }

  .detail_html {
    span {
      line-height: 40px;
    }
  }
  .ql-indent-2 ,.ql-indent-1{
    img {
      width: 40%;
    }
  }

  .ql-align-center {
    img {
      width: 50%;
    }
  }

  .detail_html {
    margin: 0 auto;
    width: 60vw;
  }
</style>
